/* eslint-disable */
// This code was generated by genapi
// Changes to this file will be lost if the code is regenerated
import http from './_http';

export function getByKey(query) {
  return http.get('/api/Dictionary/GetByKey', { params: query });
}

export function getList(query) {
  return http.get('/api/Dictionary/GetList', { params: query });
}

export function getParentList() {
  return http.get('/api/Dictionary/GetParentList');
}

export function create(data) {
  return http.post('/api/Dictionary/Create', data);
}

export function update(data) {
  return http.post('/api/Dictionary/Update', data);
}

export function del(query) {
  return http.delete('/api/Dictionary/Delete', { params: query });
}

