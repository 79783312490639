export const debugEnable = process.env.NODE_ENV === 'development';

export function showTableTotal(total, range) {
  return `${range[0]}-${range[1]} of ${total} items`;
}

export function stringSorter(a, b, index) {
  let stringA = a[index];
  let stringB = b[index];
  if (stringA && stringB) {
    stringA = stringA.toUpperCase();
    stringB = stringB.toUpperCase();
  }
  if (stringA < stringB) return -1;
  if (stringA > stringB) return 1;
  return 0;
}

export function numberSorter(a, b, index) {
  return a[index] - b[index];
}
