/* eslint-disable */
// This code was generated by genapi
// Changes to this file will be lost if the code is regenerated
import http from './_http';

export function getLoginWithMenus() {
  return http.get('/api/Account/GetLoginWithMenus');
}

export function getTodo(query) {
  return http.get('/api/Account/GetTodo', { params: query });
}

export function getSelfTodo() {
  return http.get('/api/Account/GetSelfTodo');
}

export function changePassword(data) {
  return http.post('/api/Account/ChangePassword', data);
}

export function sendResetPasswordEmail(query) {
  return http.post('/api/Account/SendResetPasswordEmail', null, { params: query });
}

export function resetPassword(data) {
  return http.post('/api/Account/ResetPassword', data);
}

