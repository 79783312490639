const messages = {
  'menu.setting': '设置',
  'menu.menu': '菜单',
  'menu.role': '角色',
  'menu.user': '用户',
  'menu.dictionary': '系统字典',
  'menu.emailtemplate': '邮件模板',
  'menu.home': '主页',
  'menu.techinfo': '技术信息查询',
  'menu.drawing': '图纸查询',
  'menu.bom': 'BOM查询',
  'menu.masterdata': '主数据',
  'menu.abbuser': 'ABB用户',
  'menu.supplieruser': '供应商用户',
  'menu.supplierbasic': '供应商基础信息',
  'menu.suppliermaterial': '供应商物料',
  'menu.ordermng': '订单管理',
  'menu.inventorysurvey': '库存调查',
  'menu.quality': '质量管理',
  'menu.ncr': 'NCR处理',
  'menu.polist': '订单执行',
  'menu.poncr': 'NCR退货',
  'menu.pot6': '发货时间申请',
  'menu.podelivery': '送货单',
  'menu.filequery': '文件查询',
  'menu.periodreport': '周期检验报告',
  'menu.leavefactoryreport': '整机出厂测试报告',
  'menu.materialgroup': '物料组',
  "menu.supplierresource": '供应商资源信息',
  "menu.mold": '模具管理',
  'menu.moldunfilled': '待完善信息模具采购列表',
  'menu.moldorder': '模具订单',
  'menu.help': '帮助',
};
export default messages;
