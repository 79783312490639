import React from 'react';
import { Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './HttpError.module.less';
import { useSelector } from 'react-redux';
import {
  AUTH_STATUS,
  selectStatus,
  selectPermission,
} from '@/features/auth/authSlice';

const ErrorContent = ({ code, home, layout }) => {
  const intl = useIntl();
  const perms = useSelector(selectPermission);
  return (
    <div className={layout ? styles.layout : null}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          {intl.formatMessage({ id: `p.errorpage.${code}` })}
        </div>
        <div className={styles.desc}>
          {code === 403 && perms.length === 0
            ? intl.formatMessage({ id: `p.errorpage.${code}.app` })
            : intl.formatMessage({ id: `p.errorpage.${code}.desc` })}
        </div>
      </div>
      {perms.includes('home') && (
        <div className={styles.link}>
          <Link to={home || '/'}>
            <Button type="primary" ghost icon={<HomeOutlined />}>
              {intl.formatMessage({ id: `p.errorpage.home` })}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

const HttpError = props => {
  const authstatus = useSelector(selectStatus);
  if (props.layout && authstatus === AUTH_STATUS.SUCCEEDED) {
    return (
      <props.layout>
        <ErrorContent code={props.code} home={props.home} layout={true} />
      </props.layout>
    );
  } else {
    return (
      <ErrorContent code={props.code} home={props.home || '/'} layout={false} />
    );
  }
};
export default HttpError;
